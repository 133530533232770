/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Checkbox, createStyles, FormControlLabel, FormGroup, Grid, makeStyles, Switch, Theme } from '@material-ui/core';
import { FormApi } from 'final-form';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { Field, FieldInputProps, Form, FormSpy } from 'react-final-form';
import { showNotification } from '../../App';
import { RvLoader } from '../../components/Loader';
import { ClientNotesSettings, DocumentPublisherSettings, FutureTimeSynchronization, StaffBudgetSettings, TaskPublisherSettings } from '../ApplicationSettingsRepository';
import { useTenant } from '../../hooks/useTenant';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            padding: '0 30px',
        },
        textField: {
            width: '100%'
        },
        buttonWrapper: {
            margin: '0 -10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            '& > button': {
                margin: '0 10px',
            }
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            flex: 1,
        },
        formGroup: {
            alignItems: 'flex-start',
            '& .MuiFormControlLabel-root': {
                marginLeft: 0,
            }
        }
    })
);

interface FormState {
    enableClientNotesSync: boolean;
    publishDocuments: boolean;
    publishDocumentFolders: boolean;
    enableFutureTimeSynchronization: boolean;
    enableBudgetSync: boolean;
    publishTasks: boolean;
}
interface ClientNotesTabState {
    isLoading: boolean;
    isFormChanged: boolean;
    onSubmitLoading: boolean;
}

interface ClientNotesTabProps {
    clinetNotesData: ClientNotesSettings | null;
    documentPublisherData: DocumentPublisherSettings | null;
    futureTimeSynchronizationData: FutureTimeSynchronization | null;
    staffBudgetSettingsData: StaffBudgetSettings | null;
    taskPublisherSettingsData: TaskPublisherSettings | null;
    onSave?: (
        clinetNotesData: ClientNotesSettings | null, 
        documentPublisherData: DocumentPublisherSettings | null, 
        futureTimeSynchronizationData: FutureTimeSynchronization | null,
        staffBudgetSettingsData: StaffBudgetSettings | null,
        taskPublisherSettingsData: TaskPublisherSettings | null
    ) => void;
}

export default function ClientNotesTab(props: ClientNotesTabProps) {

    const classes = useStyles();

    const tenant = useTenant();

    const [state, setState] = useState<ClientNotesTabState>({
        isLoading: false,
        isFormChanged: false,
        onSubmitLoading: false,
    });

    const [formState] = useState<FormState>({
        enableClientNotesSync: props.clinetNotesData ? props.clinetNotesData!.config.enableClientNotesSync : false,
        publishDocuments: props.documentPublisherData && props.documentPublisherData.config ? props.documentPublisherData.config.publishDocuments : false,
        publishDocumentFolders: props.documentPublisherData && props.documentPublisherData.config ? props.documentPublisherData.config.publishDocumentFolders : false,
        enableFutureTimeSynchronization: props.futureTimeSynchronizationData ? props.futureTimeSynchronizationData.enableFutureTimeSynchronization : false,
        enableBudgetSync: props.staffBudgetSettingsData ? props.staffBudgetSettingsData.config.enableBudgetSync : false,
        publishTasks: props.taskPublisherSettingsData ? props.taskPublisherSettingsData.config.publishTasks : false,
    });

    // tslint:disable-next-line: no-any
    const [updatePublishingAndSnycConfig] = useMutation<UpdatePublishingAndSyncSettingsData, UpdatePublishingAndSyncSettingsParams>(UpdatePublishingAndSyncSettingsQuery);

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    // const required = (value: any) => {
    //     return value ? undefined : 'Required';
    // };

    const onReset = (form: FormApi<FormState>) => () => {
        form.setConfig('keepDirtyOnReinitialize', false);
        form.batch(() => {
            form.reset();
        });
        form.setConfig('keepDirtyOnReinitialize', true);
    };

    const onSubmit = (form: FormApi<FormState>, values: FormState) => {

        setState((prevState) => {
            return {
                ...prevState,
                onSubmitLoading: true
            };
        });

        // tslint:disable-next-line: no-console
        updatePublishingAndSnycConfig({
            variables: {
                enableClientNotesSync: values.enableClientNotesSync,
                publishDocuments: values.publishDocuments,
                enableFutureTimeSynchronization: values.enableFutureTimeSynchronization,
                publishDocumentFolders: values.publishDocumentFolders,
                enableBudgetSync: values.enableBudgetSync,
                publishTasks: values.publishTasks
            }
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any }) => {
            if (results.data) {
                showNotification(null, 'Successfully submitted', 'info');  

                const initial: FormState = {
                    enableClientNotesSync: values.enableClientNotesSync,
                    publishDocuments: values.publishDocuments,
                    publishDocumentFolders: values.publishDocumentFolders,
                    enableFutureTimeSynchronization: values.enableFutureTimeSynchronization,
                    enableBudgetSync: values.enableBudgetSync,
                    publishTasks: values.publishTasks
                };

                form.reset(initial);

                setState((prevState) => {
                    return {
                        ...prevState,
                        onSubmitLoading: false
                    };
                });
        
                if (props.onSave && props.clinetNotesData && props.documentPublisherData && props.futureTimeSynchronizationData) {
                    const tempClientNotesData = props.clinetNotesData;
                    tempClientNotesData.config.enableClientNotesSync = values.enableClientNotesSync;

                    const tempDocumentPublisherData = props.documentPublisherData;
                    tempDocumentPublisherData.config.publishDocuments = values.publishDocuments;
                    tempDocumentPublisherData.config.publishDocumentFolders = values.publishDocumentFolders;

                    const tempFutureTimeSynchronizationData = props.futureTimeSynchronizationData;
                    tempFutureTimeSynchronizationData.enableFutureTimeSynchronization = values.enableFutureTimeSynchronization;

                    const tempStaffBudgetSettingsData = props.staffBudgetSettingsData;
                    tempStaffBudgetSettingsData!.config.enableBudgetSync = values.enableBudgetSync;

                    const tempTaskPublisherSettingsData = props.taskPublisherSettingsData;
                    tempTaskPublisherSettingsData!.config.publishTasks = values.publishTasks;

                    props.onSave(
                        tempClientNotesData, 
                        tempDocumentPublisherData, 
                        tempFutureTimeSynchronizationData,
                        tempStaffBudgetSettingsData,
                        tempTaskPublisherSettingsData
                    );
                }   
            }
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            showNotification('Failed to update data client notes settings.', reason, 'error');
            setState((_prevState) => {
                return {
                    ..._prevState,
                    onSubmitLoading: false
                };
            });
        });
    };

    const onChangge = (input: FieldInputProps<FormState, HTMLElement>, form: FormApi<FormState>) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        
        input.onChange(checked);

        if (!checked) { form.change('publishDocumentFolders', false); }
    };
    
    const sourceSystem = tenant.data?.tenant.sourceSystem === 'PracticeEvolve';

    return (
        <div className={classes.root}>
            {state.isLoading ? (
                <div className={classes.loaderWrapper}>
                    <RvLoader />
                </div>
            ) : (
                <Form
                    onSubmit={(values, form: FormApi<FormState>) => onSubmit(form, values)}  
                    initialValues={formState}
                    initialValuesEqual={() => true}
                    keepDirtyOnReinitialize={true}
                    subscription={{
                        submitting: true,
                        pristine: true
                    }}
                    render={({
                        handleSubmit,
                        form,
                        submitting,
                        pristine,
                        values
                    }) => (
                            <form onSubmit={handleSubmit} id="ClientNotesTab">
                                <FormSpy 
                                    subscription={{ pristine: true }}
                                    // tslint:disable-next-line: no-shadowed-variable
                                    onChange={props => {
                                        onFormValueChanged(props);
                                    }}
                                />
                                
                                <Grid container={true} spacing={3}>
                                    <Grid item={true} xs={12} md={12} justify="flex-start">
                                        <FormGroup
                                            className={classes.formGroup}
                                        >
                                            <Field
                                                name="enableBudgetSync"
                                                subscription={{touched: true, error: true, value: true}}
                                            >                                            
                                                {({ input, meta }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                {...input}
                                                                checked={input.value}
                                                                onChange={input.onChange}
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Enable Budget Sync"
                                                    />
                                                )}
                                            </Field>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} justify="flex-start">
                                        <FormGroup
                                            className={classes.formGroup}
                                        >
                                            <Field
                                                name="publishDocuments"
                                                subscription={{touched: true, error: true, value: true}}
                                            >                                            
                                                {({ input, meta }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                {...input}
                                                                checked={input.value}
                                                                onChange={onChangge(input, form)}
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Publish Documents"
                                                    />
                                                )}
                                            </Field>
                                            {sourceSystem ? (
                                                <FormSpy subscription={{ values: true }}>
                                                    {/* tslint:disable-next-line: no-shadowed-variable */}
                                                    {({ values }) => (
                                                        <Field
                                                            name="publishDocumentFolders"
                                                            subscription={{touched: true, error: true, value: true}}
                                                        >                                            
                                                            {({ input, meta }) => (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            {...input}
                                                                            checked={input.value}
                                                                            onChange={input.onChange}
                                                                            color="primary"
                                                                            disabled={!values.publishDocuments}
                                                                        />
                                                                    }
                                                                    label="Publish Document Folders"
                                                                />
                                                            )}
                                                        </Field>
                                                    )}
                                                </FormSpy>
                                            ) : undefined}
                                        </FormGroup>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} justify="flex-start">
                                        <FormGroup
                                            className={classes.formGroup}
                                        >
                                            <Field
                                                name="publishTasks"
                                                subscription={{touched: true, error: true, value: true}}
                                            >                                            
                                                {({ input, meta }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                {...input}
                                                                checked={input.value}
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Publish Tasks"
                                                    />
                                                )}
                                            </Field>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} justify="flex-start">
                                        <FormGroup
                                            className={classes.formGroup}
                                        >
                                            <Field
                                                name="enableFutureTimeSynchronization"
                                                subscription={{touched: true, error: true, value: true}}
                                            >                                            
                                                {({ input, meta }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                {...input}
                                                                checked={input.value}
                                                                onChange={input.onChange}
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Enable Future Time Synchronisation"
                                                    />
                                                )}
                                            </Field>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} justify="flex-start">
                                        <FormGroup
                                            className={classes.formGroup}
                                        >
                                            <Field
                                                name="enableClientNotesSync"
                                                subscription={{touched: true, error: true, value: true}}
                                            >                                            
                                                {({ input, meta }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                {...input}
                                                                checked={input.value}
                                                                onChange={input.onChange}
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Enable Client Notes Sync"
                                                    />
                                                )}
                                            </Field>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} justify="flex-end">
                                        <div className={classes.buttonWrapper}>
                                            <Button 
                                                variant="outlined" 
                                                color="primary"
                                                type="submit"
                                                disabled={submitting || pristine || state.onSubmitLoading}
                                            >
                                                Save
                                            </Button>
                                            <Button 
                                                variant="outlined" 
                                                color="primary"
                                                type="button"
                                                disabled={submitting || pristine}
                                                onClick={onReset(form)}
                                            >
                                                Reset
                                            </Button>
                                        </div>
                                    </Grid>
                                    {/* {printJson(values)} */}
                            </Grid>
                            </form>
                        )}
                />
            )}
        </div>
    );
}

// tslint:disable-next-line: no-any
export function printJson(values: any) {
    if (values) {
        return (
            <>
                <pre>
                    {JSON.stringify(values, undefined, 2)}
                </pre>
            </>
        );
    } else {
        return (
            <>
                <FormSpy subscription={{ values: true }}>
                    {/* tslint:disable-next-line: no-shadowed-variable */}
                    {({ values }) => (
                        <pre>
                            {JSON.stringify(values, undefined, 2)}
                            {/* <RenderCount /> */}
                        </pre>
                    )}
                </FormSpy>
            </>
        );
    }
}

type UpdatePublishingAndSyncSettingsData = {
    applicationSettings: ApplicationSettings;
};

type ApplicationSettings = {
    publishingAndSyncSettings: PublishingAndSyncSettings;
};

type PublishingAndSyncSettings = {
    update: Update;
};

type Update = {
    error:  null;
    status: boolean;
};

type UpdatePublishingAndSyncSettingsParams = {
    enableClientNotesSync: boolean;
    publishDocuments: boolean;
    publishDocumentFolders: boolean;
    enableFutureTimeSynchronization: boolean;
    enableBudgetSync: boolean;
    publishTasks: boolean;
};

const UpdatePublishingAndSyncSettingsQuery = gql`
    mutation UpdatePublishingAndSyncSettings(
        $enableClientNotesSync: Boolean
        $publishDocuments: Boolean
        $publishDocumentFolders: Boolean
        $enableFutureTimeSynchronization: Boolean
        $enableBudgetSync: Boolean
        $publishTasks: Boolean
    ) {
        applicationSettings {
            publishingAndSyncSettings {
                update(
                    enableClientNotesSync: $enableClientNotesSync
                    publishDocuments: $publishDocuments
                    publishDocumentFolders: $publishDocumentFolders
                    enableFutureTimeSynchronization: $enableFutureTimeSynchronization
                    enableBudgetSync: $enableBudgetSync
                    publishTasks: $publishTasks
                ) {
                    error
                    status
                }
            }
        }
    }
`;